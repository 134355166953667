import { FC, Fragment, useEffect, useState } from 'react';
import { Card, Col, Container, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import LoginBackground from '../components/LoginBackground';
import LoginBackgroundMobile from '../components/LoginBackgroundMobile';
import LoginBackgroundTablet from '../components/LoginBackgroundTablet';
import LoginForm from '../components/LoginForm';
import Footer from '../layout/parts/Footer';
import { maintenanceModeUrl, webResourceExists } from '../utils/get-base-64.helper';
import { IUser } from '../entities/user.entity';
import { RootState } from '../redux/store';
import { useSelector } from 'react-redux';

const Login: FC = () => {

  const [isMaintenanceMode, setIsMaintenanceMode] = useState<boolean>(false);

  const user: IUser | null = useSelector((s: RootState) => (s.auth.user?.value as IUser) || null);

  useEffect(() => {
    // Fetch the stop file to check if the website is in maintenance mode
    webResourceExists(maintenanceModeUrl)
    // webResourceExists(maintenanceModeUrl, user?.username ? user.username : '')
      .then((res) => setIsMaintenanceMode(res))
      .catch(() => setIsMaintenanceMode(false))
  }, []);

  return (
    <Fragment>
      <LoginBackgroundMobile />
      <LoginBackgroundTablet />
      <LoginBackground />
      <Container fluid as="section" className="login-content d-flex">
        <Row className="flex-grow-1">
          <Col xs={{ span: 10, offset: 1 }} md={{ span: 6, offset: 3 }} lg={{ span: 4, offset: 4 }}>
            <Card className="m-auto">
              <Card.Body className="text-center text-dark border border-dark rounded-3 bg-color-palette-4">
                <h2>Benvenuto!</h2>
                <LoginForm verticalSpacing={2} isMaintenanceMode={isMaintenanceMode} />
                <h6 className="my-3 fw-bold">
                  <Link to="/forgot-password">Password dimenticata?</Link >
                </h6>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
      <Footer variant={"dark"} />
    </Fragment>
  );
};

export default Login;