import { FC, Fragment, useState } from 'react';
import { Alert, Tab, Tabs } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { IProfessionalEntity } from '../../entities/professional.entity';
import { RootState } from '../../redux/store';
import ProfessionalCertifications from '../shared/ProfessionalCertifications';
import ProfessionalCourses from '../shared/ProfessionalCourses';
import ProfessionalPersonalData from '../shared/ProfessionalPersonalData';
import ProfessionalTrainingCredits from '../shared/ProfessionalTrainingCredits';
import CertficationForm from './CertficationForm';
import CertficationNonStandardForm from './CertficationNonStandardForm';
import CertificationListRetroattive from './CertificationListRetroattive';

interface IProps {
    codiceFiscale: string;
}

const Professional: FC<IProps> = (props) => {
    const [show, setShow] = useState(true);

    const professional: IProfessionalEntity | null = useSelector((s: RootState) => (s.professional.value));

    return (
        <Fragment>
            {
                professional != null && (professional.dataNascita == null || professional.dataNascita.length == 0 ||
                    professional.dataPrimaIscrizione == null || professional.dataPrimaIscrizione.length == 0) ? (
                    <Alert variant="warning" show={show} onClose={() => setShow(false)} dismissible>
                        <Alert.Heading>Attenzione! dati anagrafici incompleti.</Alert.Heading>
                        <p>I campi DATA NASCITA e/o DATA PRIMA ISCRIZIONE non sono valorizzati.</p>
                        <hr />
                        <span>Accedere ad albo unico per popolarli ed entro 24 ore le anagrafiche verranno allineate automaticamente.</span>
                    </Alert>
                ) : ""
            }
            <Tabs defaultActiveKey="personalData">
                <Tab eventKey="personalData" title="Anagrafica">
                    <ProfessionalPersonalData codiceFiscale={props.codiceFiscale} />
                </Tab>
                <Tab eventKey="trainingCredits" title="Report Status">
                    <ProfessionalTrainingCredits codiceFiscale={props.codiceFiscale} />
                </Tab>
                {/* 
                <Tab eventKey="trainingCreditsImateria" title="Status CFP Im@teria - Aggiornato al 21/12/21">
                    <ProfessionalTrainingCreditsImateria codiceFiscale={props.codiceFiscale} />
                </Tab> 
                */}
                <Tab eventKey="courses" title="Corsi">
                    <ProfessionalCourses codiceFiscale={props.codiceFiscale} disableButtonExportPdf={true} disableButtonMoodle={false} />
                </Tab>
                <Tab eventKey="certifications" title="Certificazioni / Esoneri">
                    <ProfessionalCertifications codiceFiscale={props.codiceFiscale} />
                </Tab>
                <Tab eventKey="cfp_assignment" title="Assegna CFP">
                    <CertficationForm codiceFiscale={props.codiceFiscale} />
                </Tab>
                {/* <Tab eventKey="cfp_non_standard" title="CFP Fuori Standard">
                    <CertificationListRetroattive codiceFiscale={props.codiceFiscale} />
                </Tab> */}
            </Tabs>
        </Fragment>
    );
};

export default Professional;